<template>
  <div>
    <v-container class="main-container d-flex flex-wrap">
      <div class="col-12 col-mm-8 pa-1 mt-8 ">

        <TopContent :importantcontent="false" ></TopContent>

        <p class="mt-4 mr-2" style="font-size: 24px;color: #aeaeae">اشتراک ویژه</p>
        <img src="../assets/images/examples/permiumuser.png" width="100%"/>

        <div class="mt-8 d-flex justify-space-around flex-wrap">

          <a :href="'https://server.mehranmedia.ir/pay/payment?priceId=1&userId='+this.$store.state.userId">
          <div class="paidblock" >
            <div class="paidsubblock" style="background: linear-gradient(#cb0039, #9a0f0f);" >
              <p style="font-size: 32px">یک ماهه</p>
              <p class="ma-0" style="font-size: 24px">تومان 25,000</p>
            </div>
            <p class="ma-0 mt-2" style="font-size: 32px;color: #ff5c71;">برنزی</p>
          </div>
          </a>

          <a :href="'https://server.mehranmedia.ir/pay/payment?priceId=2&userId='+this.$store.state.userId">
          <div class="paidblock">
            <div class="paidsubblock" style="background: linear-gradient(#05cb00, #0f9a19);" >
              <p style="font-size: 32px">سه ماهه</p>
              <p class="ma-0" style="font-size: 24px">تومان 65,000</p>
            </div>
            <p class="ma-0 mt-2" style="font-size: 32px;color: #64ff5c;">نقره ای</p>
          </div>
          </a>

          <a :href="'https://server.mehranmedia.ir/pay/payment?priceId=3&userId='+this.$store.state.userId">
          <div class="paidblock">
            <div class="paidsubblock" style="background: linear-gradient(#ff970f, #ffbc07);" >
              <p style="font-size: 32px">شش ماهه</p>
              <p class="ma-0" style="font-size: 24px">تومان 100,000</p>
            </div>
            <p class="ma-0 mt-2" style="font-size: 32px;color: #fdff5c;">طلایی</p>
          </div>
          </a>

          <a :href="'https://server.mehranmedia.ir/pay/payment?priceId=4&userId='+this.$store.state.userId">
          <div class="paidblock">
            <div class="paidsubblock" style="background: linear-gradient(#0fdaff, #05b5bb);" >
              <p style="font-size: 32px">یک ساله</p>
              <p class="ma-0" style="font-size: 24px">تومان 150,000</p>
            </div>
            <p class="ma-0 mt-2" style="font-size: 32px;color: #0fdbff;">الماسی</p>
          </div>
          </a>

        </div>



      </div>
      <div class="col-12 col-mm-4 py-6 px-lg-6 px-2">

        <LiveSearch></LiveSearch>

        <LastPosts></LastPosts>

        <RelatedPosts></RelatedPosts>

      </div>
    </v-container>


  </div>
</template>

<script>
import TopContent from "../components/TopContent";
import RelatedPosts from "../components/RelatedPosts";
import LastPosts from "../components/LastPosts";
import LiveSearch from "../components/LiveSearch";



export default {
  name: 'App',
  components : {
    RelatedPosts,
    LastPosts,
    TopContent,
    LiveSearch
  },
  data() {
    return {
      //
    }
  },
  metaInfo() {
    return {
      title:'اشتراک ویژه',
      link: [{rel: 'canonical', href: this.$store.state.url+'/permiumuser'}]
    }
  },
  methods : {
   /* payment(id){
      window.location.href =  'https://server.mehranmedia.ir/pay/payment?priceId='+id+'&userId='+this.$store.state.userId;
    }*/
  }
};
</script>

<style>
.paidblock{
  text-align: center;
  margin: 20px 4px;
  width: 230px;
  background-color: #232331;
  border-radius: 20px;
  padding-bottom: 12px;
  user-select: none;
  cursor: pointer;
}

.paidsubblock{
  padding: 20px 0;
  border-radius: 20px;
  color: white;
}
</style>

